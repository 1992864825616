<template>
  <b-container fluid>
    <b-row class="p-3">
      <b-col>
        <b-row
          class="d-flex"
          align-v="center"
        >
          <h1 class="mr-5">Categories</h1>
          <div>
            <b-btn
              variant="primary"
              @click.prevent="initItem"
            >Add New</b-btn>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="8">
        <b-table
          responsive
          striped
          hover
          :items="items"
          :fields="fields"
        >
          <template
            #cell(name)="data"
          >{{ data.value.en }} ({{ data.value.zh }})</template>
          <template #cell(actions)="data">
            <b-link
              style="margin-right:5px;"
              href="#"
              @click.prevent="editItem(data.item)"
            >
              <i class="fas fa-edit fa-lg" title="Edit" />
            </b-link>
            <b-link
              href="#"
              v-b-modal.deleteModal
              @click.prevent="toUpdate = data.item.id"
            >
              <i
                class="fas fa-trash fa-lg"
                style="color:red;"
                title="Delete"
              />
            </b-link>
          </template>
        </b-table>
      </b-col>
      <b-col md="4">
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            :label="item.id ? 'Edit ' + item.name.en : 'Add Category'"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-form-group
              v-for="language in languages"
              :key="'name_' + language.id"
            >
              <label>Name ({{ language.name }})</label>
              <b-form-input
                v-model="item.name[language.code]"
                required
              ></b-form-input>
            </b-form-group>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
          >
            Save
            <i v-if="saving" class="fas fa-spinner fa-spin" style="margin-left:3px;"/>
            <i v-if="saveStatus" :class="`fas fa-${saveStatus}`" style="margin-left:3px;"/>
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-modal
      id="deleteModal"
      ref="deleteModal"
      title="Confirm Delete?"
      @ok="updateItem('deleted')"
    >This category will be deleted</b-modal>
  </b-container>
</template>

<script>
import { makeGetRequest } from '@/util'

export default {
  data () {
    return {
      saving: false,
      saveStatus: null,
      items: [],
      fields: [
        { key: 'id', label: 'Id', isRowHeader: true },
        { key: 'name', label: 'Name' },
        'actions'
      ],
      item: {
        name: {
          en: '',
          zh: ''
        }
      },
      languages: [],
      toUpdate: null
    }
  },
  methods: {
    initItem () {
      const { languages } = this
      const item = { name: {} }
      languages.forEach((language) => {
        item.name[language.code] = ''
      })
      this.item = item
      this.sponsorImage = []
    },
    editItem (item) {
      this.item = { ...item }
    },
    setSaveStatus (status) {
      setTimeout(() => this.saveStatus = status, 50)
      setTimeout(() => this.saveStatus = '', 2000)
    },
    async onSubmit () {
      this.saving = true
      const { item } = this
      let url = `${this.$config.apiUrl}/categories`
      let method = 'post'
      if (item.id) {
        url += `/${item.id}`
        method = 'put'
      }
      try {
        await this.axios({
          url,
          method,
          data: item
        })
        this.setSaveStatus('check')
        this.getItems()
        this.initItem()
      } catch (error) {
        // console.error(error)
        this.setSaveStatus('times')
      } finally {
        this.saving = false
      }
    },
    async getItems () {
      try {
        const url = `${this.$config.apiUrl}/categories`
        const items = await makeGetRequest(url)
        this.items = items
      } catch (error) {
        // console.error(error)
      }
    },
    async updateItem (status) {
      const { toUpdate } = this
      if (!toUpdate) return
      try {
        await this.axios.put(`${this.$config.apiUrl}/categories/${toUpdate}`, { status })
        const index = this.items.indexOf(
          this.items.find((i) => i.id === Number(toUpdate))
        )
        this.items.splice(index, 1)
      } catch (error) {
        // console.error(error)
      }
    },
    async getAsyncData () {
      try {
        const languages = await makeGetRequest(`${this.$config.apiUrl}/languages`)
        this.languages = languages
        this.initItem()
      } catch (error) {
        // console.error(error)
      }
    }
  },
  created () {
    this.getItems()
    this.getAsyncData()
  }
}
</script>

<style>
</style>
